import React from 'react';
import { Waypoint } from 'react-waypoint';

import Event from './Event';

class Month extends React.Component {
    render() {
        let title = this.props.data['label'];
        let titleSize = this.props.data['fontSize'];
        let events = this.props.data['events'];

        var right = true;

        return (
            <div className="month">
                

                <Waypoint onLeave={() => this.props.onLeave(this.props.index + 1)}>
                    <h1 className="month-label" style={titleSize ? {fontSize: `${titleSize}vw`}: {}}>{ title }</h1>
                </Waypoint>


          
                {
                    events.map((value, i) => {
                        var style = {transform: ''}

                        if (right) {
                            style.marginLeft = 'calc(50vw + 8vw)';
                        }
                        else {
                            style.marginLeft = 'calc(50vw - 8vw - 30vw - 4vw)';
                      
                        }

                        right = !right;

                        return  (
                            <Event right={right} picture={value.picture} style={style} />
                        )
                    })
                }
            
            </div>
        );
    }
}

export default Month;
