import React from 'react';
import Month from './components/Month';
import Timeline from './components/Timeline';

import Pic1 from './resources/3_27.JPG';
import Pic2 from './resources/4_6.png';
import Pic3 from './resources/4_14.png';
// import Pic4 from './resources/4_14_2.JPG';
import Pic5 from './resources/4_14_3.jpg';
import Pic6 from './resources/5_5.jpg';
import Pic7 from './resources/5_7.jpg';

import Pic18 from './resources/6_6.png';
import Pic8 from './resources/6_8.png';
import Pic9 from './resources/6_22.JPG';
import Pic17 from './resources/6_30.png';

import Pic10 from './resources/test.png';
import Pic14 from './resources/7_4.JPG';
import Pic16 from './resources/7_9.png';
import Pic11 from './resources/7_14.png';
import Pic12 from './resources/8_3.JPG';
import Pic15 from './resources/8_17.png'
import Pic13 from './resources/8_19.png';


var months = [
	{
		label: 'March 8th, 2019',
		fontSize: 10,
		events: [
			{picture: Pic1, date: 27},
		] 
	},
	{
		label: 'April',
		fontSize: 9,
		events: [
			{picture: Pic2, date: 27},
			{picture: Pic3, date: 27},
			{picture: Pic5, date: 27},

		] 
	},
	{
		label: 'May',
		fontSize: 9,
		events: [
			{picture: Pic18, date: 27},
			{picture: Pic6, date: 27},
			{picture: Pic7, date: 27},
		] 
	},
	{
		label: 'June',
		fontSize: 9,
		events: [
			{picture: Pic8, date: 27},
			{picture: Pic9, date: 27},
			// {picture: Pic10, date: 27},		
			{picture: Pic17, date: 27},			

		] 
	},
	{
		label: 'July',
		fontSize: 9,
		events: [
			{picture: Pic16, date: 27},			

			{picture: Pic14, date: 27},	


			{picture: Pic11, date: 27},			
		] 
	},
	{
		label: 'August',
		fontSize: 9,
		events: [
			{picture: Pic12, date: 27},		
			{picture: Pic15, date: 27},		
			{picture: Pic13, date: 27},			
		] 
	},
	{
		label: 'September 8th, 2019.      To be continued...',
		fontSize: 9,
		events: [
			
		] 
	},
]

var colors = [
	'#ECB0E1',
	'#D9D0DE',
	'#074F57',
	'#25283D',
	'#FF595E',
	'#FEF9FF',
	'#006BA6',
]



class App extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			current: 0
		}
	}

	render() {
	
		return (
			<div className="master" style={{'backgroundColor': colors[this.state.current]}}>
				<div className='intro'>
					<h1 style={{fontSize: '6vw', marginBottom: '0', marginTop: '0', paddingTop: '2vh'}}>Christine,</h1>
					<h1 style={{fontSize: '6vw', marginBottom: '2vh', marginTop: '0'}}>Happy 6 months!</h1>
					<h1>Here's some math for you (since I'm a nerd):</h1>
					<h1>We've been together for 6 months, or about 15.8 million seconds. </h1>
					<h1>My phones shutter speed is 1/250th of a second.</h1>
					<h1>So here are some of my favorite .00000002 percents of our time together</h1>
				</div>
				

				<div className="parent">
					{ 	
						months.map((value, i) => {
							return <Month index={i} key={i} data={value} onLeave={(i) => this.setState({current: i})} />
						})	
					}

					<Timeline />
				</div>
				
			</div>
		);
	};
}

export default App;
