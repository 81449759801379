import React from 'react';
import Heart from '../resources/heart.png';


class Timeline extends React.Component {
    render() {
        return (
            <div className="timeline">
                <span class="dot"></span>

                <img src={Heart} style={{zIndex: -1, position: 'absolute', bottom: '100vh', marginLeft: '-24vw', width: '50vw'}}/>
            </div>
        );
    }
}

export default Timeline;
