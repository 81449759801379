import React from 'react';


class Event extends React.Component {
    render() {
        return (
            <div style={{position: 'relative'}}>
                <div className='imgContainer' style={this.props.style}>
                    <img 
                        src={this.props.picture} 
                        style={{padding: '2vw'}}
                        className={'picture'}
                    />
                    <img 
                        src={this.props.picture} 
                        className={'picture'}
                        style={{padding: '2vw', position: 'relative', opacity: 0}}
                    />

                    {!this.props.right ? (
                        <div className='connecting' style={{
                            height: '2vw', 
                            width: '7vw', 
                            backgroundColor: 'white',
                            position: 'absolute',
                            top: '50%',
                            left: '-7vw',
                            backgroundColor: 'transparent'
                        }}/>
                    ) : (
                        <div className='connecting' style={{
                            height: '2vw', 
                            width: '7vw', 
                            backgroundColor: 'white',
                            position: 'absolute',
                            top: '50%',
                            right: '-7vw',
                            backgroundColor: 'transparent'
                        }}/>
                    )}

                    
                </div>

                
            </div>
        );
    }
}

export default Event;
